const portfolioData = [
  {
    id: 1,
    img: "img/portfolio/port1.jpg",
    category: "",
  },

  {
    id: 2,
    img: "img/portfolio/port2.jpg",
    category: "",
  },
  {
    id: 3,
    img: "img/portfolio/port3.jpg",
    category: "",
  },
  {
    id: 4,
    img: "img/portfolio/port4.jpg",
    category: "",
  },
  {
    id: 5,
    img: "img/portfolio/port5.jpg",
    category: "",
  },
  {
    id: 6,
    img: "img/portfolio/port6.jpg",
    category: "",
  },
  {
    id: 7,
    img: "img/portfolio/port7.jpg",
    category: "",
  },
  {
    id: 8,
    img: "img/portfolio/port8.jpg",
    category: "",
  },
  {
    id: 9,
    img: "img/portfolio/port9.jpg",
    category: "",
  },
  {
    id: 10,
    img: "img/portfolio/port10.jpg",
    category: "",
  },
  {
    id: 11,
    img: "img/portfolio/port11.jpg",
    category: "",
  },
  {
    id: 12,
    img: "img/portfolio/port12.jpg",
    category: "",
  },
];
export default portfolioData;
